<script lang="ts">
    import { onMount } from "svelte";
    import { INPUT_EVENT_TYPE, COLOR, Chessboard, MARKER_TYPE } from "cm-chessboard/src/cm-chessboard/Chessboard";
    import { Chess } from 'chess.js';
    let chessboardElement: HTMLElement;
    let chessboard;
    let chess

    // Initialization logic
    let chessboardId = "chessboard";
    $: if (onMount && chessboardElement) {
        let config = {
            position: "start",
            style: {
                cssClass: "default",
                showCoordinates: true, // show ranks and files
                //borderType: BORDER_TYPE.thin, // thin: thin border, frame: wide border with coordinates in it, none: no border
                aspectRatio: 1, // height/width. Set to `undefined`, if you want to define it only in the css.
                //moveMarker: MARKER_TYPE.frame, // the marker used for moves
            },
            sprite: {
                url: "../assets/images/chessboard-sprite-staunty.svg",
            },
        };
        
        // Initialize Chessboard
        chessboard = new Chessboard(chessboardElement, config);

        // Initialize Chess engine
        chess = new Chess();

        // Enable moves
        chessboard.enableMoveInput(handleMove);
    }

    // CHESS LOGIC
    function handleMove(event) {
        console.log("event", event);

        event.chessboard.removeMarkers(undefined, MARKER_TYPE.dot);
        if (event.type === INPUT_EVENT_TYPE.moveStart) {
            const moves = chess.moves({ square: event.square, verbose: true });
            for (const move of moves) {
                event.chessboard.addMarker(move.to, MARKER_TYPE.dot);
            }
            return moves.length > 0;
        } else if (event.type === INPUT_EVENT_TYPE.moveDone) {
            const move = `${event.squareFrom}${event.squareTo}` // ex: 'e4e5'
            const result = chess.move(move, { sloppy: true });
            if (result) {
                event.chessboard.disableMoveInput();
                event.chessboard.setPosition(chess.fen());
                const possibleMoves = chess.moves({ verbose: true });
                if (possibleMoves.length > 0) {
                    const randomIndex = Math.floor(
                        Math.random() * possibleMoves.length
                    );
                    const randomMove = possibleMoves[randomIndex];
                    setTimeout(() => {
                        // smoother with 500ms delay
                        chess.move({
                            from: randomMove.from,
                            to: randomMove.to,
                        });
                        event.chessboard.enableMoveInput(
                            handleMove,
                            COLOR.white
                        );
                        event.chessboard.setPosition(chess.fen());
                    }, 500);
                }
            } else {
                console.warn("invalid move", move);
            }
            return result;
        }
    }
</script>

<!-- Import the css -->
<svelte:head>
    <link rel="stylesheet" href="../assets/styles/cm-chessboard.css" />
</svelte:head>

<div class="grid grid-cols-8">
    <div class="col-span-1"></div>
    <div bind:this={chessboardElement} id={chessboardId} class="col-span-6" />
    <div class="col-span-1"></div>
</div>
